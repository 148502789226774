import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes';
import { getCookie } from '@/core/mixins/useCookie'

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (!to.meta.requireAuth) {
    if (!getCookie("token")) return next("/login");
  } else {
    if (to.path === "/login" && getCookie("token")) {
      return next("/");
    } else {
      next()
    }
  }
  next()
})

export default router
