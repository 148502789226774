// export const AJAX_BASE_URL = "http://6xfp97.natappfree.cc/" // 请求地址
export const AJAX_BASE_URL = "https://disable.tzyl12349.com/prod-api/" // 请求地址

export const AJAX_TIMEOUT = "5000" // 请求超时时间

export const TOKEN_KEY = "token"

export const USER_INFO = "userInfo"


export const menuPathList = [
    { name: "首页", path: "/home" },
    { name: "工单管理", path: "/product", },
    { name: "服务人员", path: "/renovation", },
    { name: "服务对象", path: "/renovation", },
    { name: "系统设置", path: "/system", },
    { name: "平台用户", path: "/system/userManage" },
    { name: "微信用户", path: "/system/wxUser" },
    { name: "服务类别", path: "/system/dictionaryManage" },
    { name: "服务项目", path: "/system/dictionaryManage" },
];
