import axios from 'axios'
import { getToken, removeToken } from "./userToken";

import { AJAX_BASE_URL, AJAX_TIMEOUT } from '@/config'
var instance = axios.create({
    baseURL: AJAX_BASE_URL,
    timeout: AJAX_TIMEOUT
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    let { method, url, params, data } = config
    // 获取token
    let token = getToken()

    if (token) config.headers['token'] = token;
    // 在发送请求之前打印日志
    params = params || data
    // try {
    //     let str = params && Object.keys(params).length ? decodeURIComponent(JSON.stringify(params)) : ""
    //     console.fakeLog(`${method}请求了${url}${str ? ',参数为' + str : ''}`);
    // } catch (error) {
    //     console.log('参数qs转换出错，但不要紧', error);
    // }
    return config;
}, function ({ message }) {
    return Promise.reject(message);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // console.fakeLog('响应为', response.data);

    return response;
}, function ({ message, response }) {
    //如果是401则跳转到登录页面
    if (response?.status === 401) {
        removeToken();
        window.location.reload();
    }
    return Promise.reject(message);
});

export const $get = async (url, params) => {
    let { data } = await instance.get(url, { params })
    return data
}

export const $post = async (url, params) => {
    let { data } = await instance.post(url, params)
    return data
}

export const $put = async (url, params) => {
    let { data } = await instance.put(url, params)
    return data
}

export const $del = async (url, params) => {
    let { data } = await instance.delete(url, params)
    return data
}
export const $getFile = async (url, params) => {
    let { data } = await instance({
        method: 'get',
        url,
        params: params,
        responseType: 'blob'
    })
    return data
}


export default instance;