<script setup>
import * as echarts from "echarts";
import { provide } from "vue";
provide("echarts", echarts);
</script>
<template>
  <router-view />
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}
</style>