import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import 'ant-design-vue/dist/antd.css';
import "./assets/sence.scss";
import 'lib-flexible/flexible'; //自适应插件
import core from '@/core'
import "dayjs/locale/zh-cn";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

// import echarts from './core/echarts';

import { createPinia } from 'pinia'
const pinia = createPinia()

const app = createApp(App)
// app.config.globalProperties.$echarts = echarts
// app.use(store)
app.use(router)
app.use(core)
app.use(pinia)
app.use(Viewer, {
    defaultOptions: {
        // 自定义默认配置
    }
})
app.mount('#app')
