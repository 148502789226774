export default [
    {
        path: '/',
        redirect: '/home',
        component: () => import('@/views/layout/Index.vue'),
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    title: "首页",
                },
                component: () => import('@/views/home/Index.vue')
            },
            {
                path: '/workOrder',
                name: 'workOrder',
                meta: {
                    title: "服务工单",
                },
                children: [
                    {
                        path: '/workOrder/library',
                        name: 'workOrderLibrary',
                        meta: {
                            title: "服务工单 / 工单列表",
                        },
                        component: () => import('@/views/workOrder/Library.vue')
                    },
                    {
                        path: '/workOrder/details',
                        name: 'details',
                        meta: {
                            title: "服务工单 / 服务工单信息",
                        },
                        component: () => import('@/views/workOrder/Details.vue')
                    },
                    {
                        path: '/workOrder/cancleOrder',
                        name: 'cancleOrder',
                        meta: {
                            title: "服务工单 / 已废除工单",
                        },
                        component: () => import('@/views/workOrder/CancleOrder.vue')
                    },
                    {
                        path: '/workOrder/projectInfo',
                        name: 'projectInfo',
                        meta: {
                            title: "服务工单信息",
                        },
                        component: () => import('@/views/workOrder/ProjectInfo.vue')
                    },
                ]
            },
            {
                path: '/servicePerson',
                name: 'servicePerson',
                meta: {
                    title: "服务人员",
                },
                children: [
                    {
                        path: '/servicePerson/list',
                        name: 'servicePersonList',
                        meta: {
                            title: "服务人员",
                        },
                        component: () => import('@/views/servicePerson/List.vue')
                    },
                    {
                        path: '/servicePerson/personInfo',
                        name: 'personInfo',
                        meta: {
                            title: "服务人员信息",
                        },
                        component: () => import('@/views/servicePerson/PersonInfo.vue')
                    },
                ]
            },
            {
                path: '/serviceObject',
                name: 'serviceObject',
                meta: {
                    title: "服务对象",
                },
                children: [
                    {
                        path: '/serviceObject/list',
                        name: 'serviceObjectList',
                        meta: {
                            title: "服务对象",
                        },
                        component: () => import('@/views/serviceObject/List.vue')
                    },
                    {
                        path: '/serviceObject/objectInfo',
                        name: 'objectInfo',
                        meta: {
                            title: "服务对象信息",
                        },
                        component: () => import('@/views/serviceObject/ObjectInfo.vue')
                    },
                ]
            },
            {
                path: '/system',
                name: 'system',
                meta: {
                    title: "系统设置",
                },
                children: [
                    {
                        path: '/system/platformUsers',
                        name: 'platformUsers',
                        meta: {
                            title: "系统设置 / 平台用户",
                        },
                        component: () => import('@/views/system/PlatformUsers.vue')
                    },
                    {
                        path: '/system/wxUser',
                        name: 'wxUser',
                        meta: {
                            title: "系统设置 / 平台用户",
                        },
                        component: () => import('@/views/system/WxUser.vue')
                    },
                    {
                        path: '/system/serviceType',
                        name: 'serviceType',
                        meta: {
                            title: "系统设置 / 平台用户",
                        },
                        component: () => import('@/views/system/ServiceType.vue')
                    },
                    {
                        path: '/system/serviceProject',
                        name: 'serviceProject',
                        meta: {
                            title: "系统设置 / 平台用户",
                        },
                        component: () => import('@/views/system/ServiceProject.vue')
                    },
                ]
            }
        ]
    },
    {
        path: '/login',
        meta: {
            requireAuth: true
        },
        component: () => import('@/views/login/Index.vue'),
    }
]