import {
    Card,
    Drawer,
    Button,
    Row,
    Switch,
    Upload,
    Col,
    Progress,
    Skeleton,
    ConfigProvider,
    Layout,
    Statistic,
    Menu,
    Dropdown,
    Breadcrumb,
    TreeSelect,
    List,
    Tag,
    Input,
    Form,
    Checkbox,
    Tabs,
    Pagination,
    Table,
    Spin,
    Modal,
    Select,
    Tree,
    Popover,
    Avatar,
    Divider,
    Radio,
    DatePicker,
    Descriptions,
    Tooltip,
    InputNumber,
    Image,
    Empty,
    Steps,
    Timeline,
    TimePicker,
    Cascader,
    Popconfirm
} from 'ant-design-vue';

export default (app) => {
    app.use(TimePicker);
    app.use(Timeline);
    app.use(Steps);
    app.use(Empty);
    app.use(Image);
    app.use(Tree);
    app.use(InputNumber);
    app.use(DatePicker);
    app.use(Tooltip);
    app.use(Switch);
    app.use(Descriptions);
    app.use(Progress);
    app.use(Radio);
    app.use(Popover);
    app.use(Divider);
    app.use(Modal);
    app.use(Select);
    app.use(Spin);
    app.use(Table);
    app.use(Pagination);
    app.use(Menu);
    app.use(List);
    app.use(Tabs);
    app.use(Dropdown);
    app.use(Button);
    app.use(TreeSelect);
    app.use(Avatar);
    app.use(Skeleton);
    app.use(Breadcrumb);
    app.use(ConfigProvider);
    app.use(Tag);
    app.use(Card);
    app.use(Form);
    app.use(Row);
    app.use(Col);
    app.use(Statistic);
    app.use(Input);
    app.use(Layout);
    app.use(Checkbox);
    app.use(Upload);
    app.use(Drawer);
    app.use(Cascader);
    app.use(Popconfirm);
}