import { TOKEN_KEY, USER_INFO } from "../../config";
import { getCookie, setCookie, removeCookie } from "./useCookie";

export let getToken = () => getCookie(TOKEN_KEY);
export let setToken = (val, expires) => setCookie(TOKEN_KEY, val, expires);
export let removeToken = () => removeCookie(TOKEN_KEY);

export let getUserInfo = () => getCookie(USER_INFO);
export let setUserInfo = (val, expires) => setCookie(USER_INFO, val, expires);
export let removeUserInfo = () => removeCookie(USER_INFO);
